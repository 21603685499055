$near-black: #1f1f1f;
$yellow: #FFE45D;
$green: #97DE00;
$red: #de005d;
$light-yellow: lighten($yellow, 15%);
$lightest-yellow: lighten($yellow, 20%);
$light-red: lighten($red, 15%);
$lightest-red: lighten($red, 30%);
$light-green: lighten($green, 15%);
$lightest-green: lighten($green, 33%);
$disabled: #adadad;

$small-screen: 500px;
$large-screen: 850px;

$stroke-dash: 298.5;

$working-icon: "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>🚧</text></svg>";

@mixin backgroundColors {
  .backgroundColor-- {
    &yellow {
      background-color: $yellow;
    }
    &light-yellow {
      background-color: $light-yellow;
    }
    &lightest-yellow {
      background-color: $lightest-yellow;
    }
    &green {
      background-color: $green;
    }
    &light-green {
      background-color: $light-green;
    }
    &lightest-green {
      background-color: $lightest-green;
    }
    &red {
      background-color: $red;
    }
    &light-red {
      background-color: $light-red;
    }
    &lightest-red {
      background-color: $lightest-red;
    }
  }
}

@mixin color {
  .color-- {
    &yellow {
      color: $yellow;
    }
    &light-yellow {
      color: $light-yellow;
    }
    &lightest-yellow {
      color: $lightest-yellow;
    }
    &green {
      color: $green;
    }
    &light-green {
      color: $light-green;
    }
    &lightest-green {
      color: $lightest-green;
    }
    &red {
      color: $red;
    }
    &light-red {
      color: $light-red;
    }
    &lightest-red {
      color: $lightest-red;
    }
  }
}