/* You can add global styles to this file, and also import other style files */

@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:wght@200;400;500&display=swap');

html, body {
  border: none;
  margin: 0;
  padding: 0;
  background-color: $near-black;
  color: white;
  font-weight: 400;
  font-family: 'Sofia Sans', sans-serif;
}

body {
  font-size: 1.5em;
}

h1, h2, h3 {
  font-weight: 200;
}

a {
  text-decoration: none;
  color: $red;
  cursor: pointer;
  &:visited {
    color: $lightest-red;
  }
  &:hover {
    color: $light-red;
  }
}

.disabled, a:not([href]) {
  color: $disabled;
  cursor: url($working-icon) 16 16, default;
}

@keyframes clock-animation {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: $stroke-dash;
  }
}